import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Chart6 from "../Chart/Chart6";
import NewChart from '../Chart/NewChart';
import Customer from "./Customer";
import { motion } from 'framer-motion'


const User = ({ userData, numbers, room, isAuth, setIsAuth }) => {
    const socket = useRef();

    // for queue
    const [queue, setQueue] = useState(userData.queue_numbers);
    // for calls
    const [calls, setCalls] = useState([]);
    //for channels
    const [channels, setChannels] = useState("");

    //for filtering
    const [counterConversation, setCounterConversation] = useState([]);
    const [counterQueue, setCounterQueue] = useState([]);


    useEffect(() => {
        socket.current = new WebSocket("ws://apiqueue.azerizone.net:1024");

        socket.current.onopen = function (e) {
            // console.log("[open] Соединение установлено");
            // console.log("User is connected")
            socket.current.send(`{"type": "connect", "room": "${userData.room}"}`);
            setChannels(userData.channels_number)
        };

        socket.current.onclose = function (event) {
            if (event.wasClean) {
                alert(
                    `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
                );
            } else {
                // например, сервер убил процесс или сеть недоступна
                // обычно в этом случае event.code 1006
                alert("[close] Соединение прервано");
            }
        };

        socket.current.onerror = function (error) {
            alert(`[error] ${error.message}`);
        };
    }, []);

    useEffect(() => {
        socket.current.onmessage = function (event) {
            let callInfo = JSON.parse(event.data);
            console.log(callInfo);

            // if call has started
            if (callInfo.state === "NEW") {
                setCalls((oldCalls) => [...oldCalls, callInfo]);
                // amountConversation(callInfo)
            } else {
                operationWithCalls(callInfo);
            }
        };

        amountConversation()

    }, [calls]);

    //duration
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // console.log('This will run every second!');
    //         // setDuration(prevState => prevState + 1)
    //
    //         let updatedDuration = calls.map(call => {
    //             let temp = call
    //             temp.duration = Number(temp.duration) + 10
    //
    //             return temp;
    //         })
    //
    //         setCalls(updatedDuration)
    //     }, 10000);
    //     return () => clearInterval(interval);
    // }, [calls]);


    const operationWithCalls = (callInfo) => {
        if (callInfo.state === "UPDATE") {
            let existingCalls = calls.map(call => {
                if (call.callId === callInfo.callId) {
                    return callInfo;
                } else {
                    return call;
                }
            })
            // console.log("UPDATED");

            setCalls(existingCalls)
        }

        if (callInfo.state === "HANGUP") {
            let remainingCalls = calls.filter(
                (call) => call.callId !== callInfo.callId
            );

            setCalls(remainingCalls);
            // console.log("HANGUP");
        }
    };

    const amountConversation = () => {
        const filteredQueues = calls.filter(call => queue.includes(JSON.parse(call.answeredNumber)));
        setCounterQueue(filteredQueues)

        const filteredConversations = calls.filter(call => (!queue.includes(JSON.parse(call.answeredNumber)) && call.state !== 'NEW'));
        setCounterConversation(filteredConversations)
    }

    // useEffect(() => {
    //     window.onbeforeunload = function() {
    //         return 'You have unsaved changes!';
    //     }

    //     return () => {
    //         window.onbeforeunload = null;
    //     };
    // }, [])

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
            <div className="page-container">
                {/* <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ fontFamily: 'Poppins' }}
                /> */}
                <div className="main-content">
                    <Navbar userData={userData} />
                    <div className='users-info'>
                        <div>
                            <span className='user-info-title'>İstifadəçi adı: </span>
                            <span className='user-name'> {userData.user_name}</span>
                        </div>
                        <div>
                            <span className='company-info-title'>Şirkətin adı :</span>
                            <span className='company-name'> {userData.company}</span>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <div className="row">
                        <div className="col-sm-4 col-xs-6">
                            <div className="tile-stats">
                                <div className="icon"></div>
                                <div className="num" data-start="0" data-end="83" data-postfix="" data-duration="1500"
                                    data-delay="0">{calls.length} / {userData.channels_number}
                                </div>
                                <h3>Ümumi zənglər / Kanal sayı</h3>
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-6">
                            <div className="tile-stats">
                                <div className="icon"></div>
                                <div className="num" data-start="0" data-end="135" data-postfix="" data-duration="1500"
                                    data-delay="600">
                                    {counterConversation.length}
                                </div>
                                <h3>Aktiv danışıq</h3>
                            </div>
                        </div>
                        <div className="clear visible-xs"></div>
                        <div className="col-sm-4 col-xs-6">
                            <div className="tile-stats">
                                <div className="icon"></div>
                                <div className="num" data-start="0" data-end="23" data-postfix="" data-duration="1500"
                                    data-delay="1200">
                                    {counterQueue.length}
                                </div>
                                <h3>Növbədə gözləyənlər</h3>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        {/* <Chart5 userData={userData}/> */}
                        {/* <Chart6 userData={userData} /> */}
                        <NewChart userData={userData} />
                    </div>

                </div>
            </div>
            <Footer />
        </motion.div>
    )
}

export default User
