import React from 'react'
import { Rings } from 'react-loader-spinner'


const Loading = () => {
    return (
        <>
            <Rings
                height="300"
                width="300"
                color='red'
                ariaLabel='loading'
            />
        </>
    )
}

export default Loading