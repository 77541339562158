import React, { useContext, useEffect, useRef, useState } from "react";
import './assets/css/bootstrap.css';
import './assets/css/custom.css';
import './assets/css/neon-core.css';
import './assets/css/font-icons/entypo/css/entypo.css';
import './assets/css/font-icons/font-awesome/css/font-awesome.min.css';
import './assets/css/skins/black.css';
// import './assets/css/skins/blue.css';
// import './assets/css/skins/cafe.css';
// import './assets/css/skins/facebook.css';
// import './assets/css/skins/green.css';
// import './assets/css/skins/purple.css';
// import './assets/css/skins/red.css';
// import './assets/css/skins/white.css';
// import './assets/css/skins/yellow.css';
import './assets/css/neon-theme.css';
import './assets/css/neon-forms.css';
import logo from './assets/images/vats/logo.png';
import { useNavigate } from 'react-router-dom';
import { NewContext } from './components/Authentication/Login/NewContext';
import Customer from "./components/Users/Customer";
import { AuthContext } from "./components/Authentication/Login/AuthContext";
import {
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion } from 'framer-motion'

function App() {
  const myLocation = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const socket = useRef();
  const [connected, setConnected] = useState(false);
  let str = "";

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [numbers, setNumbers] = useState([]);
  const [room, setRoom] = useState('');
  const [userData, setUserData] = useState({})
  const [nums, setNums] = useState('');
  const [channelsNumber, setChannelsNumber] = useState("");
  const [user_name, setUserName] = useState('');
  const [last_visit, setLastVisit] = useState('');

  const [hasRouteChanged, sethasRouteChanged] = useState(false);
  const location = useLocation();


  const handleSubmit = async (e) => {
    e.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    let url = "http://apiqueue.azerizone.net/api/client/login";

    let urlOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": enteredEmail,
        "password": enteredPassword
      })
    }

    fetch(url, urlOptions)
      .then((response) => {
        if (response.ok) {
          // setIsAuth(true)
          return response.json()

        } else {
          return response.json()
            .then(data => {
              let errorMessage = 'Authentication failed!';
              if (data && data.error && data.error.message) {
                errorMessage = data.error.message;
              }
              throw new Error(errorMessage);;
            })
        }
      })
      .then(data => {
        // authCtx.login(data.room);
        authCtx.login(data.access_token);
        setUserData(data)
        // console.log(data);
        // setNumbers(data.numbers)
        setNumbers(data.queue_numbers)


        // setIsAuth(true)

        navigate('/hesab', {
          replace: true,
        })

        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("userNumbers", JSON.stringify(data.queue_numbers));
        localStorage.setItem("userName", JSON.stringify(data.user_name));
        localStorage.setItem("userCompany", JSON.stringify(data.company));
        localStorage.setItem("userRoom", JSON.stringify(data.room));
        localStorage.setItem("channelsNumber", JSON.stringify(data.channels_number));
        // localStorage.setItem("user_name", JSON.stringify(data.user_name));
        localStorage.setItem("last_visit", JSON.stringify(data.last_visit));
      })
      .catch(err => {
        alert(err.message)
      })
  }

  // useEffect(() => {
  //   const info = JSON.parse(localStorage.getItem('userData'));
  //   const name = JSON.parse(localStorage.getItem('userName'));
  //   const company = JSON.parse(localStorage.getItem('userCompany'));
  //   const numbers = JSON.parse(localStorage.getItem('userNumbers'));
  //   const room = JSON.parse(localStorage.getItem("userRoom"));
  //   const channelsNumber = JSON.parse(localStorage.getItem("channelsNumber"))
  //   // const user_name = JSON.parse(localStorage.getItem("user_name"));
  //   const last_visit = JSON.parse(localStorage.getItem("last_visit"))
  //   const nums = JSON.parse(localStorage.getItem("str"));

  //   if (info) {
  //     setUserData(info)
  //     setName(name)
  //     setCompany(company)
  //     setNumbers(numbers)
  //     setRoom(room)
  //     setNums(nums)
  //     setChannelsNumber(channelsNumber)
  //     // setUserName(user_name)
  //     setLastVisit(last_visit)
  //   }
  // }, []);

  useEffect(() => {
    sethasRouteChanged(true);
  }, [location]);

  useEffect(() => {
    if (window.performance) {
      if (window.performance.navigation.type == "reload" || window.performance.navigation.type == 1) {
        if (!hasRouteChanged) window.location.href = "/";
      }
    }
  }, []);

  const showUser = () => {
    const info = JSON.parse(localStorage.getItem('userData'));
    if (window.location.pathname === "/hesab" && authCtx.isLoggedIn) {
      return (
          <Customer
            userData={userData}
            numbers={numbers}
            room={room}
            nums={nums}
            setNums={setNums}
            isAuth={isAuth}
            setIsAuth={setIsAuth}
          />
      )
    }
  }

  const showForm = () => {
    if (window.location.pathname === "/") {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="page-body login-page login-form-fall loaded login-form-fall-init" style={{ height: '100vh' }}>
          <div className="login-container">
            <div className="login-header login-caret">
              <div className="login-content"><img src={logo} width="210" alt="" />
                <p className="description">Öz panelinizə daxil olun</p>
              </div>
            </div>
            <div className="login-progressbar">
              <div></div>
            </div>
            <div className="login-form">
              <div className="login-content">
                <form method="post" role="form" id="form_login" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon"><i className="entypo-user"></i></div>
                      <input type="email"
                        className="form-control" name="username" id="username" placeholder="Email"
                        autoComplete="off"
                        ref={emailInputRef}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon"><i className="entypo-key"></i></div>
                      <input type="password"
                        className="form-control" name="password" id="password" placeholder="Şifrə"
                        autoComplete="off"
                        ref={passwordInputRef}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block btn-login"><i
                      className="entypo-login"></i>
                      Daxil Olun
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )
    }
  }

  return (
    <div>

      <AnimatePresence>
        {showUser()}
        {showForm()}
      </AnimatePresence>

    </div>
  )
}

export default App;
