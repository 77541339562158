import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GoLightBulb } from "react-icons/go";
import { BsHandThumbsUp, BsHandThumbsDown } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return tooltipItem.yLabel;
            }
        }
    },
    scales: {
        y: {
            max: 30,
            min: 0,
            ticks: {
                stepSize: 5,
            }
        },
        x: {
            ticks: {
                maxTicksLimit: 10,
            }
        }
    }
};


const Chart5 = ({ userData }) => {
    const [pingData, setPingData] = useState('');

    const [label, setLabel] = useState([]);
    const [chartData, setChartData] = useState([]);


    const myFc = () => {
        let url = "http://apiqueue.azerizone.net/api/network/ping";

        let urlOptions = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'access_token': userData.access_token
            })
        }

        const date = new Date();
        let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let time = hours + ":" + minutes;

        fetch(url, urlOptions)
            .then((response) => {
                if (response.ok) {

                    return response.json()
                } else {
                    return response.json()
                        .then(data => {
                            let errorMessage = 'Ping is failed!';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        })
                }
            })
            .then(data => {
                setPingData(data);
                setLabel(prevState => [...prevState, time]);
                setChartData(prevState => [...prevState, data.time])

                console.log('Label: ' + label, 'Data: ' + chartData)
            })
    }

    useEffect(() => {

        setInterval(() => {
            myFc()
        }, 6000)
        myFc()
    }, []);

    const data = {
        labels: label,
        datasets: [
            {
                label: 'Ping',
                data: chartData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ]
    };


    return (
        <>
            <div className="col-md-8">
                <Line options={options} data={data} />
            </div>
            <div className="col-md-4">
                <div className='ping-description'>
                    <div className='ping-box'>
                        <div className='ping-square low'></div>
                        <p className='ping-text'>aşağı</p>
                    </div>
                    <div className='ping-box'>
                        <div className='ping-square normal'></div>
                        <p className='ping-text'>orta</p>
                    </div>
                    <div className='ping-box'>
                        <div className='ping-square high'></div>
                        <p className='ping-text'>yüksək</p>
                    </div>
                    <div className='ping-box'>
                        <div className='ping-square ping-none'></div>
                        <p className='ping-text'>ping yoxdur</p>
                    </div>
                </div>
                {!pingData && <div className='ping-state' style={{ background: '#000000', background: 'linear-gradient(to right, #000000, #434343)' }}>
                    <div className='state-icon'>
                        {/* <i className='fa fa-close'></i> */}
                        <CgCloseO/>
                    </div>
                </div>}
                {pingData.state === 'low' && <div className='ping-state' style={{ background: '#56ab2f', background: 'linear-gradient(to right, #56ab2f, #a8e063)' }}>
                    <div className='state-icon'>
                        {/* <i className='far fa-thumbs-up'></i> */}
                        <BsHandThumbsUp/>
                    </div>
                </div>}
                {pingData.state === 'normal' && <div className='ping-state' style={{ background: '#fdc830', background: 'linear-gradient(to right, #fdc830, #f37335)' }}>
                    <div className='state-icon'>
                        {/* <i className='far fa-lightbulb'></i> */}
                        <GoLightBulb/>
                    </div>
                </div>}
                {pingData.state === 'high' && <div className='ping-state' style={{ background: '#f00000', background: 'linear-gradient(to right, #f00000, #dc281e)' }}>
                    <div className='state-icon'>
                        {/* <i className='fas fa-minus-circle'></i> */}
                        <BsHandThumbsDown/>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Chart5;