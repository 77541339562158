import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import NewContextProvider from "./components/Authentication/Login/NewContext";
import store from './components/Authentication/Login/NewContext';
import App from "./App";
import AuthContextProvider from './components/Authentication/Login/AuthContext';


ReactDOM.render(
  <React.StrictMode>
     
      <BrowserRouter>
          <AuthContextProvider store={store}>
              <App />
          </AuthContextProvider>
      </BrowserRouter>
      
  </React.StrictMode>,
  document.getElementById('root')
);


