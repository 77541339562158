import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewContext = React.createContext({
    room: '',
    isLoggedIn: false,
    login: () => { },
    logOut: () => { }
});

let url = "https://apiqueue.azerizone.net/api/client/login";

export const NewContextProvider = (props) => {
    const [isLogin, setIsLogin] = useState(false);
    const [room, setRoom] = useState("")
    const userIsLoggedIn = !!room;
    const navigate = useNavigate();

    const loginHandler = () => {
        setIsLogin(room);
    }

    const logoutHandler = () => {
        setIsLogin(null);
        navigate('/');
    }

    const contextValue = {
        room: room,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }

    return (
        <NewContext.Provider value={contextValue}>
            <div>{props.children}</div>
        </NewContext.Provider>
    )
}

export default NewContextProvider;