import React, { useContext } from 'react';
import { NewContext } from "../Authentication/Login/NewContext";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/vats/logo.png';
import { AuthContext } from '../Authentication/Login/AuthContext';

const Navbar = ({ userData }) => {
    const authCtx = useContext(AuthContext);


    let url = "http://apiqueue.azerizone.net/api/client/logout";

    authCtx.logOut = () => {
        // localStorage.removeItem("userData");
        // localStorage.removeItem("name");
        // localStorage.removeItem("userCompany");
        // localStorage.removeItem("userNumbers");
        // localStorage.removeItem("userRoom");
        // localStorage.removeItem("token");
        // localStorage.removeItem("nums");
        // localStorage.removeItem("str");
        // localStorage.removeItem("channelsNumber");
        // localStorage.removeItem("userName");
        // localStorage.removeItem("lastVisit");

        localStorage.clear();
    }


    return (
        <>
            <div className="row navbar">
                <div className="col-md-6 col-sm-8 clearfix">
                    <ul className="user-info pull-left pull-none-xsm">
                        <p style={{ fontSize: '35px', fontWeight: 'bold', color: '#000' }}>
                            <img src={logo} alt="" />
                        </p>
                    </ul>
                </div>
                <div className="col-md-6 col-sm-4 clearfix hidden-xs">
                    <ul className="list-inline links-list pull-right logout">
                        <li onClick={authCtx.logOut} style={{ fontSize: '16px' }}><NavLink to="/">
                            Hesabdan çıxış <i className="entypo-logout right"></i>
                        </NavLink></li>
                        <li><span className='info'>*</span> Səhifəni yeniləsəniz, ana səhifəyə keçid edəcəksiniz!</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Navbar;