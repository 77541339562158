import React from 'react';
import logo from '../../assets/images/vats/logo.png';
import {Link, NavLink} from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const Footer = () => {
    return (
        <>
            <footer className="footer-container">
                {/*<div className="pull-right"><a*/}
                {/*    href="https://themeforest.net/item/neon-bootstrap-admin-theme/6434477?ref=Laborator"*/}
                {/*    target="_blank"><strong>Purchase this theme for $25</strong></a></div>*/}
                {/*&copy; 2022 <strong>Neon</strong> Admin Theme by <a href="https://laborator.co/"*/}
                {/*                                                    target="_blank">Laborator</a>*/}

                <div className="footer-content">

                    <div className="footer-icons">
                        <a href="https://www.facebook.com/azerizone/" target="_blank" className="footer-link">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/azerizone" target="_blank"  className="footer-link" >
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a href="https://az.linkedin.com/company/azerizone" target="_blank"  className="footer-link">
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;