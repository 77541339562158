import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

import { AiOutlineMinusCircle } from "react-icons/ai";
import { GoLightBulb } from "react-icons/go";
import { BsHandThumbsUp, BsHandThumbsDown } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import Loading from '../Loading';

const NewChart = ({ userData }) => {
    const [pingData, setPingData] = useState('');

    const [label, setLabel] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [items, setItems] = useState([]);
    let datas = [];

    const myFc = () => {
        let url = "http://apiqueue.azerizone.net/api/network/ping";

        let urlOptions = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'access_token': userData.access_token
            })
        }

        const date = new Date();
        let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let time = hours + ":" + minutes;

        fetch(url, urlOptions)
            .then((response) => {
                if (response.ok) {

                    return response.json()
                } else {
                    return response.json()
                        .then(data => {
                            let errorMessage = 'Ping is failed!';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                                // time = 0;
                                // data.state = 'none'
                            }
                            throw new Error(errorMessage);
                        })
                }
            })
            .then(data => {
                setPingData(data);
                setLabel(prevState => [...prevState, time]);
                setChartData(prevState => [...prevState, data.time])

            })
    }

    useEffect(() => {
        setInterval(() => {
            myFc()

        }, 30000)

        myFc()
    }, []);

    return (
        <>
            <p className='chart-title'>VATS serveri ilə əlaqə keyfiyyəti</p>
            {!pingData && <div style={{height: '100vh'}}><Loading/></div>}
            {pingData && (
                <>
                    <div className='col-md-8'>
                        
                        <ResponsiveContainer width="100%" aspect={2}>
                            <AreaChart width={500} height={300} data={chartData.map((time) => ({
                                name: label,
                                ping: time
                            }))}>
                                <CartesianGrid />
                                <XAxis dataKey="name" padding={{ left: 13, right: 13 }} tickFormatter={(time) => {
                                    if (chartData.length >= 17) {
                                        chartData.shift(0, 1)
                                        label.shift(0, 1)
                                    }

                                    return time

                                }} />
                                <YAxis domain={[0, 30]} tickCount={6} datakey="ping" />
                                <Tooltip />
                                {/* <Legend /> */}
                                <Area type="monotone" dataKey="ping" stroke="#ff5252" fill="#ff5252" strokeWidth={1} activeDot={{ r: 8 }} />
                                {/* <Line type="monotone" dataKey="ping" stroke="#8884d8" strokeWidth={2}  fill="#8884d8" /> */}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="col-md-4">
                        <div className='ping-description'>
                            <div className='ping-box'>
                                <div className='ping-square low'></div>
                                <p className='ping-text'>aşağı</p>
                            </div>
                            <div className='ping-box'>
                                <div className='ping-square normal'></div>
                                <p className='ping-text'>orta</p>
                            </div>
                            <div className='ping-box'>
                                <div className='ping-square high'></div>
                                <p className='ping-text'>yüksək</p>
                            </div>
                            <div className='ping-box'>
                                <div className='ping-square ping-none'></div>
                                <p className='ping-text'>ping yoxdur</p>
                            </div>
                        </div>
                        {pingData.state === 'none' && <div className='ping-state' style={{ background: '#000000', background: 'linear-gradient(to right, #000000, #434343)' }}>
                            <div className='state-icon'>
                                {/* <i className='fa fa-close'></i> */}
                                <CgCloseO />
                            </div>
                        </div>}
                        {pingData.state === 'low' && <div className='ping-state' style={{ background: '#56ab2f', background: 'linear-gradient(to right, #56ab2f, #a8e063)' }}>
                            <div className='state-icon'>
                                {/* <i className='far fa-thumbs-up'></i> */}
                                <BsHandThumbsUp />
                            </div>
                        </div>}
                        {pingData.state === 'normal' && <div className='ping-state' style={{ background: '#fdc830', background: 'linear-gradient(to right, #fdc830, #f37335)' }}>
                            <div className='state-icon'>
                                {/* <i className='far fa-lightbulb'></i> */}
                                <GoLightBulb />
                            </div>
                        </div>}
                        {pingData.state === 'high' && <div className='ping-state' style={{ background: '#f00000', background: 'linear-gradient(to right, #f00000, #dc281e)' }}>
                            <div className='state-icon'>
                                {/* <i className='fas fa-minus-circle'></i> */}
                                <BsHandThumbsDown />
                            </div>
                        </div>}

                    </div>
                </>
            )}

        </>
    )
}

export default NewChart;